import React from "react";
import styled from "styled-components";

function Facebook() {
  return (
    <svg
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Facebook</title>
      <Path
        d="m13.8870432 20v-7.7076412h2.6823557l.3741227-3.12292358h-3.0564784v-2.27158252c0-.58869961.4693181-1.05067662 1.0482513-1.05067662h2.1411175v-2.74461331c-.1934036-.0303349-.3917617-.04608436-.5939293-.04608436h-1.8686199c-2.0894578 0-3.783298 1.69716374-3.783298 3.78284573v2.33011108h-2.65780732v3.12292358h2.65780732v7.7076412h-9.22768891c-.8910653 0-1.60287589-.717632-1.60287589-1.6028759v-16.79424821c0-.8910653.71763198-1.60287589 1.60287589-1.60287589h16.79424821c.8910653 0 1.6028759.71763198 1.6028759 1.60287589v16.79424821c0 .8910653-.717632 1.6028759-1.6028759 1.6028759z"
        fill-rule="evenodd"
      />
    </svg>
  );
}

export default React.memo(Facebook);

const Path = styled.path`
  fill: ${(props) => props.theme.siteTheme.color};
`;
