import Link from "next/link";
import { desaturate, lighten } from "polished";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import ProfileImage from "@s/components/common/ProfileImage";
import RichTextDisplay from "@s/components/common/RichTextDisplay";

function HomeHero({ about, biographyRichText, biography }) {
  return (
    <Section>
      <Container>
        <ImageWrapper>
          <ProfileImage />
        </ImageWrapper>
        <ContentWrapper>
          <Strip />
          <Content>
            <Name>{about.name}</Name>
            <Institution>
              {about.position} | {about.institution}
            </Institution>
            <Degree>{about.degree}</Degree>
            {biographyRichText ? (
              <RichTextDisplay
                content={biographyRichText}
                multiline={false}
                alwaysIncludePadding
              />
            ) : (
              <Paragraph>{biography}</Paragraph>
            )}
            <Link href="/about">Read More</Link>
          </Content>
        </ContentWrapper>
      </Container>
    </Section>
  );
}

HomeHero.propTypes = {
  about: PropTypes.shape({
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    institution: PropTypes.string.isRequired,
    degree: PropTypes.string.isRequired,
  }).isRequired,
  retinaID: PropTypes.string.isRequired,
  imageCrops: PropTypes.object.isRequired,
  biography: PropTypes.string,
  biographyRichText: PropTypes.object,
};

export default React.memo(HomeHero);

const Section = styled.section`
  background-image: ${(props) =>
    `radial-gradient(circle at 51% 39%, ${
      props.theme.siteTheme.secondaryColor ||
      desaturate(0.2, lighten(0.2, props.theme.siteTheme.color))
    }, ${props.theme.siteTheme.color})`};
  padding: 40px 0;
  @media (min-width: 768px) {
    padding-bottom: 80px;
    padding-top: 80px;
  }
`;

const Container = styled.div`
  position: relative;
  padding-top: 220px;
`;

const ImageWrapper = styled.div`
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  height: 280px;
  left: 50%;
  margin: 0 auto;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 280px;
  transform: translateX(-50%);

  img {
    background-color: #d8d8d8;
    border-radius: 2px;
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  background-color: #fff;
  @media (min-width: 768px) {
    border-radius: 2px;
    margin: 0 auto;
    max-width: 600px;
    overflow: hidden;
  }
  @media (min-width: 1024px) {
    max-width: 800px;
  }
`;

const Strip = styled.div`
  background-color: ${(props) => props.theme.siteTheme.color};
  height: 10px;
`;

const Content = styled.div`
  padding: 90px 40px 40px;
  text-align: center;
`;

const Name = styled.h1`
  color: ${(props) => props.theme.siteTheme.color};
  font-size: 1.875rem;
  margin-bottom: 6px;
`;

const Institution = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 20px;
`;

const Degree = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 30px;
`;

const Paragraph = styled.p`
  margin: 1rem 0;
`;
