import { transparentize } from "polished";
import type { ReactNode } from "react";
import s from "styled-components";

import type { CurrentPage } from "@s/components/themes/types";
import { Section } from "../shared";

import ModernFooter from "../Footer";
import ModernHeader from "../Header";
import ModernProfile from "../Profile";

interface Props {
  children: ReactNode;
  page: CurrentPage;
}

export default function ModernLayout({ children, page }: Props) {
  return (
    <ModernTheme>
      <Layout>
        <ProfileContainer>
          <ModernProfile />
        </ProfileContainer>
        <Container>
          <Content>
            <ModernHeader />
            {page !== "contact" && page !== "notfound" && (
              <MobileProfile>
                <ModernProfile />
              </MobileProfile>
            )}
            {children}
          </Content>
        </Container>
      </Layout>
      <ModernFooter />
    </ModernTheme>
  );
}

const ModernTheme = s.main`
min-height: ${(props) =>
  props.theme.siteTheme.isPreview ? "calc(100vh - 40px)" : "100vh"};
display: flex;
flex-direction: column;
justify-content: stretch;
`;

const Layout = s.div`
flex-grow: 1;

display: grid;
grid-template-columns: 1fr 2fr;
background: white;
height: 100%;

@media (max-width: 940px) {
  grid-template-columns: 1fr;
}
`;

const ProfileContainer = s.div`
@media (max-width: 940px) {
  display: none;
  margin: 0 20px;
}
`;

const Container = s.div`
display: flex;
background: linear-gradient(to right, ${(props) =>
  transparentize(0.95, props.theme.siteTheme.color)}, ${(props) =>
  transparentize(1, props.theme.siteTheme.color)});
margin-left: -60px;
padding-left: 60px;

box-shadow: -1px 0 1px rgb(0 0 0 / 8%);

@media (max-width: 940px) {
  margin-left: 0;
  padding-left: 0;
  justify-content: center;
}
`;

const Content = s.div`
padding: 20px 0;

@media (max-width: 940px) {
  width: 100%;
}

@media (max-width: 540px) {
  padding: 0;
}
`;

const MobileProfile = s(Section)`
display: none;

@media (max-width: 940px) {
  display: block;
}
`;
