import { desaturate, lighten } from "polished";
import React from "react";
import styled from "styled-components";

import ProfileImage from "@s/components/common/ProfileImage";
import RichTextDisplay from "@s/components/common/RichTextDisplay";
import type {
  RichTextType,
  SiteEducationItemType,
  SiteImageCropsType,
  SiteResumeType,
} from "publit-shared";

interface Props {
  biography: string[];
  biographyRichText?: RichTextType | null;
  education: SiteEducationItemType[];
  retinaID: string;
  imageCrops: SiteImageCropsType;
  resume?: SiteResumeType | null;
}

function AboutHero({
  biography,
  biographyRichText,
  education,
  retinaID,
  resume,
}: Props) {
  const resumeLink =
    resume &&
    `https://assets.publit.co/${retinaID}/resume/${resume.uploadID}/${resume.name}`;

  const hasRichTextBiography = !!biographyRichText;

  return (
    <Section>
      <Container>
        <ImageWrapper>
          <ProfileImage small />
        </ImageWrapper>
        <ContentWrapper>
          <Strip />
          <Content textCenter>
            <Title>biography</Title>
            {hasRichTextBiography ? (
              <RichTextDisplay content={biographyRichText} />
            ) : (
              biography.map((content, id) => (
                <Paragraph key={`bio-${id}`}>{content}</Paragraph>
              ))
            )}
          </Content>
        </ContentWrapper>
        <EducationWrapper>
          <Strip />
          <Content padding="40px 70px">
            <Title>education</Title>
            {education.map((content, id) => (
              <Paragraph key={`edu-${id}`}>{content.title}</Paragraph>
            ))}
          </Content>
        </EducationWrapper>
        {resume && resumeLink && (
          <ResumeWrapper>
            <Strip />
            <Content padding="40px 70px">
              <Title>{resume.sectionTitle || "Resume"}</Title>
              <Paragraph>
                <ResumeLink
                  href={resumeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {resume.name}
                </ResumeLink>
              </Paragraph>
            </Content>
          </ResumeWrapper>
        )}
      </Container>
    </Section>
  );
}

export default React.memo(AboutHero);

const Section = styled.section`
  background-image: ${(props) =>
    `radial-gradient(circle at 51% 39%, ${
      props.theme.siteTheme.secondaryColor ||
      desaturate(0.2, lighten(0.2, props.theme.siteTheme.color))
    }, ${props.theme.siteTheme.color})`};
  padding: 40px 0;
  @media (min-width: 768px) {
    padding-bottom: 80px;
    padding-top: 80px;
  }
`;

const Container = styled.div`
  position: relative;
  padding-top: 120px;
`;

const ImageWrapper = styled.div`
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  height: 180px;
  left: 50%;
  margin: 0 auto;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 180px;
  transform: translateX(-50%);

  img {
    background-color: #d8d8d8;
    border-radius: 2px;
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  background-color: #fff;
  @media (min-width: 768px) {
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    overflow: hidden;
  }
  @media (min-width: 1024px) {
    max-width: 800px;
  }
`;

const EducationWrapper = styled(ContentWrapper)`
  margin-top: 40px;
  @media (min-width: 768px) {
    margin-top: 60px;
  }
`;

const ResumeWrapper = styled(ContentWrapper)`
  margin-top: 40px;
  @media (min-width: 768px) {
    margin-top: 60px;
  }
`;

const Strip = styled.div`
  background-color: ${(props) => props.theme.siteTheme.color};
  height: 10px;
`;

const Content = styled.div<{ padding?: string; textCenter?: boolean }>`
  padding: ${({ padding }) => (padding ? padding : "80px 40px 40px")};
  ${({ textCenter }) => textCenter && "text-align: center"};
`;

const Title = styled.h1`
  color: ${(props) => props.theme.siteTheme.color};
  font-size: 1.5rem;
  margin-bottom: 1.625rem;
  text-align: center;
  text-transform: uppercase;
`;

const Paragraph = styled.p`
  margin: 1rem 0;
`;

const ResumeLink = styled.a`
  font-weight: bold;
  color: ${(props) => props.theme.siteTheme.color};
`;
