import { useSite } from "@s/lib/context";

import { Link, Section, Title } from "../shared";

export default function MinimalSocial() {
  const site = useSite();

  return (
    <Section>
      <Title>Social</Title>
      {site.socialLinks.twitter &&
      site.socialLinks.twitter.trim().length > 0 ? (
        <Link
          href={site.socialLinks.twitter}
          rel="noopener noreferrer"
          target="_blank"
        >
          <p>Twitter</p>
        </Link>
      ) : null}
      {site.socialLinks.linkedIn &&
      site.socialLinks.linkedIn.trim().length > 0 ? (
        <Link
          href={site.socialLinks.linkedIn}
          rel="noopener noreferrer"
          target="_blank"
        >
          <p>LinkedIn</p>
        </Link>
      ) : null}
      {site.socialLinks.instagram &&
      site.socialLinks.instagram.trim().length > 0 ? (
        <Link
          href={site.socialLinks.instagram}
          rel="noopener noreferrer"
          target="_blank"
        >
          <p>Instagram</p>
        </Link>
      ) : null}
      {site.socialLinks.facebook &&
      site.socialLinks.facebook.trim().length > 0 ? (
        <Link
          href={site.socialLinks.facebook}
          rel="noopener noreferrer"
          target="_blank"
        >
          <p>Facebook</p>
        </Link>
      ) : null}
      {site.socialLinks.youtube &&
      site.socialLinks.youtube.trim().length > 0 ? (
        <Link
          href={site.socialLinks.youtube}
          rel="noopener noreferrer"
          target="_blank"
        >
          <p>YouTube</p>
        </Link>
      ) : null}
    </Section>
  );
}
