import { useMemo, useState } from "react";

import { useSite } from "@s/lib/context";

import RichTextDisplay from "@s/components/common/RichTextDisplay";
import { Button, Paragraph, Section, Title } from "../shared";

export default function MinimalAbout() {
  const site = useSite();
  const [isExpanded, setExpanded] = useState(false);

  const hasRichTextBiography = !!site.biographyRichText;

  const biographyText = useMemo(() => {
    if (!site.biography) return [];
    return site.biography.split("\n").filter(Boolean);
  }, [site.biography]);

  return (
    <Section>
      <Title>About</Title>
      {hasRichTextBiography ? (
        <RichTextDisplay
          content={site.biographyRichText}
          multiline={isExpanded}
          alwaysIncludePadding={true}
        />
      ) : (
        biographyText
          .slice(0, isExpanded ? site.biography?.length : 1)
          .map((content, id) => (
            <Paragraph key={`bio-${id}`}>{content}</Paragraph>
          ))
      )}
      <Button onClick={() => setExpanded((e) => !e)}>
        Read {isExpanded ? "Less" : "More"}
      </Button>
    </Section>
  );
}
