import Head from "next/head";
import { createGlobalStyle } from "styled-components";

import type { ReactNode } from "react";

export default function System({ children }: { children: ReactNode }) {
  return (
    <>
      <Head>
        <link
          href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Manrope:600,800&display=swap"
          rel="stylesheet"
        />
      </Head>
      <GlobalStyle />
      {children}
    </>
  );
}

const GlobalStyle = createGlobalStyle`
body {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: #444;
    font-size: 18px;
    line-height: 25px;
}
`;
