import s from "styled-components";

export const Section = s.section`
width: 500px;
margin: 40px auto;

@media (max-width: 540px) {
  width: auto;
  margin: 40px 20px;
}
`;

export const Title = s.h3`
margin: 1rem 0;
color: ${(props) => props.theme.siteTheme.color};
`;

export const Paragraph = s.p`
margin: 1rem 0;
`;

export const Button = s.button`
display: block;
margin: 0;
padding: 0;

background: transparent;
font: inherit;
border: none;
cursor: pointer;
color: inherit;

&:hover {
  color: ${(props) => props.theme.siteTheme.color};
}
`;

export const Link = s.a`
position: relative;
display: flex;
align-items: flex-start;
margin: 0.5rem 0;

& p {
  position: relative;
  display: inline;
}

& p::before {
  content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.4C0.668629 4.4 0.4 4.66863 0.4 5C0.4 5.33137 0.668629 5.6 1 5.6L1 4.4ZM9.42426 5.42426C9.65858 5.18995 9.65858 4.81005 9.42426 4.57574L5.60589 0.757359C5.37157 0.523045 4.99167 0.523045 4.75736 0.757359C4.52304 0.991674 4.52304 1.37157 4.75736 1.60589L8.15147 5L4.75736 8.39411C4.52304 8.62843 4.52304 9.00833 4.75736 9.24264C4.99167 9.47696 5.37157 9.47696 5.60589 9.24264L9.42426 5.42426ZM1 5.6L9 5.6V4.4L1 4.4L1 5.6Z' fill='${(
    props,
  ) => props.theme.siteTheme.color.replace("#", "%23")}' /%3E%3C/svg%3E%0A");

  opacity: 0.5;

  position: absolute;
  bottom: 0;
  right: -15px;

  display: flex;
  align-items: flex-end;

  width: 10px;
  height: 100%;
}

&:hover p::before {
  opacity: 1.0
}
`;

export const Index = s.span`
display: block;
margin-right: 5px;
`;
