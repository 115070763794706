import PropTypes from "prop-types";
import styled from "styled-components";

import SocialLinks from "../SocialLinks";

function HomeSocialLinks({ socialLinks }) {
  return (
    <Container>
      <SocialLinks links={socialLinks} />
    </Container>
  );
}

HomeSocialLinks.propTypes = {
  socialLinks: PropTypes.object.isRequired,
};

export default HomeSocialLinks;

const Container = styled.section`
  background-image: linear-gradient(to top, #f0f0f0, #ffffff);
  display: flex;
  height: 80px;
  justify-content: center;
`;
