import React from "react";
import styled from "styled-components";

function YouTube() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>YouTube</title>
      <g>
        <Path d="M0.356072 5.40436C0.566208 4.14766 1.68623 3.33496 2.96038 3.33496H17.0396C18.3138 3.33496 19.4338 4.14766 19.6439 5.40436C19.8302 6.51847 20 8.06506 20 10.0016C20 11.9382 19.8302 13.4848 19.6439 14.5989C19.4338 15.8556 18.3138 16.6683 17.0396 16.6683H2.96039C1.68623 16.6683 0.566208 15.8556 0.356072 14.5989C0.16978 13.4848 0 11.9382 0 10.0016C0 8.06506 0.16978 6.51847 0.356072 5.40436Z" />
        <path
          d="M13.3333 10.002L8.08334 13.033L8.08334 6.97086L13.3333 10.002Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default React.memo(YouTube);

const Path = styled.path`
  fill: ${(props) => props.theme.siteTheme.color};
`;
