import s from "styled-components";

import MinimalAbout from "./About";
import MinimalContact from "./Contact";
import MinimalEducation from "./Education";
import MinimalSocial from "./Social";
import MinimalWork from "./Work";

import type { Template, TemplateLayoutProps } from "../types";
import MinimalFooter from "./Footer";
import MinimalHeader from "./Header";

import NotFound from "@s/components/common/NotFound";

export function SinglePage() {
  return (
    <>
      <MinimalAbout />
      <MinimalEducation />
      <MinimalWork />
      <MinimalSocial />
      <MinimalContact />
    </>
  );
}

const Layout = ({ children }: TemplateLayoutProps) => (
  <Container>
    <MinimalHeader />
    {children}
    <MinimalFooter />
  </Container>
);

const Container = s.div`
  min-height: 100vh;
`;

const minimal: Template = {
  Layout,
  pages: {
    Home: SinglePage,
    About: SinglePage,
    Work: SinglePage,
    Contact: SinglePage,
    NotFound,
  },
};

export default minimal;
