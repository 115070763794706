import styled, { ThemeProvider } from "styled-components";
import DraftJSRender from "./draftjs-renderer";

const entityResolver = (entityType) => {
  if (entityType === "LINK") {
    return function EntityLink({ data, children }) {
      return (
        <Link target="_blank" rel="noopener noreferrer" href={data.url}>
          {children}
        </Link>
      );
    };
  }

  return null;
};

const removeAdditionalBlocksIfNotMultiline = (content, multiline) => {
  if (multiline || !content.blocks || !content.blocks.length) return content;

  return {
    ...content,
    blocks: [content.blocks[0]],
  };
};

export default function RichTextDisplay({
  content,
  multiline = true,
  alwaysIncludePadding = false,
}) {
  const theme = {
    richText: {
      multiline,
      alwaysIncludePadding,
    },
  };

  const filteredContent = removeAdditionalBlocksIfNotMultiline(
    content,
    multiline,
  );

  return (
    <RichTextContainer>
      <ThemeProvider theme={theme}>
        <DraftJSRender
          content={filteredContent}
          getBlockComponent={() => Paragraph}
          getEntityComponent={entityResolver}
        />
      </ThemeProvider>
    </RichTextContainer>
  );
}

const RichTextContainer = styled.div``;

const Paragraph = styled.p`
  margin: ${(props) =>
    props.theme.richText.multiline || props.theme.richText.alwaysIncludePadding
      ? "1rem 0"
      : "0"};
`;

const Link = styled.a`
  color: ${(props) => props.theme.siteTheme.color};
  font-weight: bold;
`;
