import Link from "next/link";
import s from "styled-components";

import { useSite, useSiteInfo } from "@s/lib/context";

export default function ModernFooter() {
  const site = useSite();
  const info = useSiteInfo();

  return (
    <Footer>
      <Title>{site.about.name}</Title>
      <Link href="/" passHref legacyBehavior>
        <HomeLink>{info.host}</HomeLink>
      </Link>
      <Designed href="https://publit.app" target="_blank">
        Designed with Publit
      </Designed>
    </Footer>
  );
}

const Footer = s.footer`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%;
margin-top: auto;
margin-bottom: 0;
padding: 70px 20px;

box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
background: white;
`;

const Title = s.h1`
font-weight: bold;
font-size: 18px;
line-height: 25px;
`;

const HomeLink = s.a`
display: block;
margin: 20px 0;

color: ${(props) => props.theme.siteTheme.color};
`;

const Designed = s.a`
display: block;

font-style: italic;
font-weight: bold;
color: ${(props) => props.theme.siteTheme.color};
`;
