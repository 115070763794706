import { useMemo } from "react";

import AboutHero from "../AboutHero";
import SocialLinkBanner from "../SocialLinkBanner";

import { useSite } from "@s/lib/context";

export default function AboutPage() {
  const site = useSite();

  const biographyText = useMemo(() => {
    if (!site.biography) return [];

    return site.biography.split("\n").filter(Boolean);
  }, [site.biography]);

  return (
    <>
      <AboutHero
        biography={biographyText}
        biographyRichText={site.biographyRichText}
        education={site.education}
        retinaID={site.retinaID}
        imageCrops={site.imageCrops}
        resume={site.resume}
      />
      <SocialLinkBanner socialLinks={site.socialLinks} />
    </>
  );
}
