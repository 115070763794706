import React from "react";
import styled from "styled-components";

function Twitter() {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 250 250"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Twitter</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <Path
          d="M78.62,226.59 C172.96,226.59 224.56,148.43 224.56,80.65 C224.56,78.43 224.56,76.22 224.41,74.02 C234.448314,66.7591086 243.113555,57.768794 250,47.47 C240.638791,51.6180177 230.708293,54.3382865 220.54,55.54 C231.24744,49.1298206 239.261235,39.0477199 243.09,27.17 C233.021575,33.1445532 222.006461,37.3551202 210.52,39.62 C194.620828,22.7139428 169.357194,18.5761258 148.895493,29.5267993 C128.433792,40.4774727 117.86275,63.7932573 123.11,86.4 C81.8689574,84.3324954 43.4447578,64.8532327 17.4,32.81 C3.78622756,56.2464473 10.7398788,86.2285948 33.28,101.28 C25.117412,101.038075 17.1327937,98.8361318 10,94.86 C10,95.07 10,95.29 10,95.51 C10.0066813,119.92593 27.2175149,140.955352 51.15,145.79 C43.598696,147.8494 35.6758059,148.150443 27.99,146.67 C34.7094793,167.564228 53.9657456,181.877839 75.91,182.29 C57.7473504,196.564319 35.3105951,204.313277 12.21,204.29 C8.12903569,204.282165 4.05204065,204.035075 0,203.55 C23.4563595,218.602766 50.749134,226.587169 78.62,226.55"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default React.memo(Twitter);

const Path = styled.path`
  fill: ${(props) => props.theme.siteTheme.color};
`;
