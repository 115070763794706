import { nanoid } from "nanoid";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

import { timezones } from "./timezones";

const COOKIE_NAME = "ps";

const getSessionID = () => {
  const cookie: Record<string, string> = {};
  document.cookie.split(";").forEach((el) => {
    const [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[COOKIE_NAME];
};

const setSessionID = () => {
  /**
   * Try to keep same session id if session cookie exists, generate a new one otherwise.
   *   - First request in a session will generate a new session id
   *   - The next request will keep the same session id and extend the TTL for 30 more minutes
   */
  const sessionId = getSessionID() ?? nanoid();
  document.cookie = `${COOKIE_NAME}=${sessionId}; Max-Age=1800; path=/; secure`;
};

const constructPayload = (pathname: string, itemID?: string) => {
  let country = "";
  let locale = "";
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    country = timezones[timezone];
    locale = navigator.languages?.length
      ? navigator.languages[0]
      : navigator.language || "en";
  } catch (e) {
    // ignore error
  }

  return JSON.stringify({
    "user-agent": window.navigator.userAgent,
    locale,
    location: country,
    referrer: document.referrer,
    href: window.location.href,
    pathname,
    item_id: itemID,
  });
};

const record = (pathname: string, itemID?: string) => {
  setSessionID();

  const body = JSON.stringify({
    timestamp: new Date().toISOString(),
    action: "page_hit",
    version: "1",
    session_id: getSessionID(),
    payload: constructPayload(pathname, itemID),
  });

  const url = "/api/analytics/track";

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { method: "POST", body, keepalive: true });
  }
};

interface TrackOptions {
  isPreview?: boolean;
  itemID?: string;
}

export const useTrack = (options: TrackOptions) => {
  const pathname = useTrackPathname();

  useEffect(() => {
    const hasPathname = pathname && pathname.trim() !== "";

    if (!hasPathname) return;

    if (options.isPreview) {
      console.log("[Pageview] Track:", pathname);
      return;
    }

    record(pathname, options.itemID);
  }, [pathname, options.itemID, options.isPreview]);
};

const useTrackPathname = () => {
  const pathname = usePathname();

  // strip _live or _sites prefixes
  return pathname?.replace(/_live\/?/, "").replace(/_sites\/\[site\]\/?/, "");
};
