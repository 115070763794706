import { useRouter } from "next/router";
import type { ComponentType } from "react";

import ComingSoonPage from "@s/components/ComingSoon";
import RootLayout from "@s/components/common/Layout";
import { useCurrentPage } from "@s/lib/helpers";
import type { StaticDataProps } from "@s/lib/types";

import type { Template, TemplatePages } from "./types";

import classic from "./classic";
import minimal from "./minimal";
import modern from "./modern";

const TEMPLATES: { [key: string]: Template } = {
  classic,
  modern,
  minimal,
};

const page = (find: (pages: TemplatePages) => ComponentType) => {
  return function InnerPage(props: StaticDataProps) {
    const currentPage = useCurrentPage();
    const { isFallback } = useRouter();

    if (isFallback) return null;

    if (props.type === "coming-soon") {
      return <ComingSoonPage />;
    } else if (props.type === "site") {
      const {
        data: { data, info },
      } = props;
      const template = TEMPLATES[data.theme.name ?? "classic"];
      const Page = find(template.pages);
      const Layout = template.Layout;

      return (
        <RootLayout data={data} info={info} page={currentPage}>
          <Layout page={currentPage}>
            <Page />
          </Layout>
        </RootLayout>
      );
    } else if (props.type === "unknown-error") {
      return (
        <div className="flex flex-col gap-2 justify-center items-center min-h-screen text-red-500 text-center">
          <p>An unexpected error occured.</p>
          {props.message && <p>{props.message}</p>}
        </div>
      );
    } else {
      return <p>unknown error</p>;
    }
  };
};

export const Home = page((p) => p.Home);
export const About = page((p) => p.About);
export const Work = page((p) => p.Work);
export const Contact = page((p) => p.Contact);
export const NotFound = page((p) => p.NotFound);
