import s from "styled-components";

export const Section = s.section<{ smallBottomPadding?: boolean }>`
width: 500px;
margin: 40px auto;
padding: ${(props) =>
  props.smallBottomPadding ? "30px 30px calc(30px - 1rem)" : "30px"};
box-sizing: border-box;

background: white;
border: 1px solid #DBDBDB;
border-radius: 6px;

@media (max-width: 540px) {
  width: auto;
  margin: 20px;
}
`;

export const Title = s.h3`
margin: 0;
color: ${(props) => props.theme.siteTheme.color};
`;

export const Paragraph = s.p`
margin: 1rem 0;
`;

export const Button = s.button`
display: block;
margin: 0;
padding: 0;

background: transparent;
font: inherit;
border: none;
cursor: pointer;
color: inherit;

&:hover {
  color: ${(props) => props.theme.siteTheme.color};
}
`;

export const Link = s.a``;
