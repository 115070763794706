import { desaturate, transparentize } from "polished";
import s from "styled-components";

import { useSite } from "@s/lib/context";

import ProfileImage from "@s/components/common/ProfileImage";

export default function MinimalHeader() {
  const site = useSite();

  return (
    <Header>
      <Profile>
        <Image>
          <ProfileImage small />
        </Image>
        <Info>
          <Title>{site.about.name}</Title>
          <Subtitle>{site.about.institution}</Subtitle>
          <Description>{site.about.degree}</Description>
        </Info>
      </Profile>
    </Header>
  );
}

const Header = s.header`
padding: 100px 0 40px;

background: url("/static/sites/themes/minimal/background.svg") no-repeat center 0%, linear-gradient(to bottom, ${(
  props,
) =>
  desaturate(0.2, transparentize(0.6, props.theme.siteTheme.color))} -20%, ${(
  props,
) => desaturate(0.2, transparentize(1, props.theme.siteTheme.color))} 80%);

@media (max-width: 540px) {
  padding: 60px 0 10px;
}
`;

const Profile = s.section`
display: flex;
align-items: center;
width: 500px;
margin: 0 auto;

@media (max-width: 540px) {
  margin: 0 20px;
  width: auto;
}
`;

const Image = s.div`
flex-shrink: 0;

width: 120px;
height: 120px;
background: ${(props) => props.theme.siteTheme.color};
border-radius: 100%;

color: white;
display: flex;
justify-content: center;
align-items: center;

& > div {
  width: 100%;
  height: 100%;
}

& img {
  border-radius: 100%;
}

@media (max-width: 540px) {
  width: 80px;
  height: 80px;
}
`;

const Info = s.div`
flex-shrink: 1;

margin-left: 20px;
`;

const Title = s.h1`
font-size: 20px;
color: ${(props) => props.theme.siteTheme.color};
`;

const Subtitle = s.h2`
font-size: 18px;
`;

const Description = s.p``;
