import type { SiteWorkSectionType } from "publit-shared";
import type { ReactNode } from "react";

import { useSite } from "@s/lib/context";
import { workLinkProps } from "@s/lib/helpers";

import RichTextDisplay from "@s/components/common/RichTextDisplay";
import { Link, Paragraph, Section, Title } from "../shared";

function WorkSection({ works }: { works: SiteWorkSectionType }) {
  return (
    <Section smallBottomPadding>
      <Title>{works.title}</Title>

      {works.items.map((item) => (
        <Link key={item.id} {...workLinkProps(item)}>
          {item.titleRichText ? (
            <RichTextDisplay
              multiline={false}
              content={item.titleRichText}
              alwaysIncludePadding={true}
            />
          ) : (
            <Paragraph>{item.title}</Paragraph>
          )}
        </Link>
      ))}
    </Section>
  );
}

interface Props {
  hideSecondaryWorks?: boolean;
  children?: ReactNode;
}

export default function ModernWork({ hideSecondaryWorks }: Props) {
  const site = useSite();

  return (
    <>
      <WorkSection works={site.primaryWorks} />
      {!hideSecondaryWorks && <WorkSection works={site.secondaryWorks} />}
    </>
  );
}
