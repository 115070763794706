import { type ReactNode, createContext, useContext } from "react";
import type { SitePageProps } from "./types";

const SiteContext = createContext<SitePageProps | null>(null);

export const SiteProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: SitePageProps;
}) => {
  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

export const useSite = () => {
  const ctx = useContext(SiteContext);

  if (!ctx) throw new Error("No site context found");

  return ctx.data;
};

export const useSiteInfo = () => {
  const ctx = useContext(SiteContext);

  if (!ctx) throw new Error("No site context found");

  return ctx.info;
};
