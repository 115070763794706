import type { Template } from "../types";

import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import Work from "./Work";

import Layout from "./Layout";

import NotFound from "@s/components/common/NotFound";

const modern: Template = {
  Layout,
  pages: {
    Home,
    About,
    Work,
    Contact,
    NotFound,
  },
};

export default modern;
