import Link from "next/link";
import React from "react";
import styled, { css } from "styled-components";

import { useSite, useSiteInfo } from "@s/lib/context";

import SocialLinks from "../SocialLinks";

function Footer() {
  const site = useSite();
  const info = useSiteInfo();

  return (
    <Container>
      <Sitemap>
        <Group>
          <GroupName>site</GroupName>
          <FlexGrid column>
            <Link passHref href="/" legacyBehavior>
              <StyledLink>Home</StyledLink>
            </Link>
            <Link passHref href="/about" legacyBehavior>
              <StyledLink>About</StyledLink>
            </Link>
            <Link passHref href="/work" legacyBehavior>
              <StyledLink>Work</StyledLink>
            </Link>
            <Link passHref href="/contact" legacyBehavior>
              <StyledLink>Contact</StyledLink>
            </Link>
          </FlexGrid>
        </Group>
        {!site.about.hideEmail &&
          site.about.email &&
          site.about.email.trim().length > 0 && (
            <Group>
              <GroupName>contact</GroupName>
              <StyledLink href={`mailto:${site.about.email}`}>
                {site.about.email}
              </StyledLink>
            </Group>
          )}
        <Group right>
          <GroupName>connect</GroupName>
          <SocialLinks isFooter links={site.socialLinks} />
        </Group>
      </Sitemap>
      <Group noMargin>
        <Bottom>
          <SiteTitle>{site.about.name}</SiteTitle>
          <Link href="/" passHref legacyBehavior>
            <SiteLink>{info.host}</SiteLink>
          </Link>
          <PublitLink
            href="https://publit.app"
            rel="noopener noreferrer"
            target="_blank"
          >
            Designed with Publit
          </PublitLink>
        </Bottom>
      </Group>
    </Container>
  );
}

export default Footer;

const linkStyle = () => css`
  color: #000;
  display: block;
  margin: 0 auto;
  min-width: 6.25rem;
  padding: 10px 16px;
`;

const Container = styled.footer`
  color: ${(props) => props.theme.siteTheme.color};
  padding: 50px 0;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05);

  @media (min-width: 620px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const Sitemap = styled.div`
  @media (min-width: 620px) {
    display: flex;
  }
`;

const Group = styled.div`
  font-size: 0.875rem;
  text-align: center;
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin-bottom: 40px;
    `};

  @media (min-width: 620px) {
    text-align: ${({ right }) => !right && "left"};
    margin-left: ${({ right }) => (right ? "auto" : "inherit")};
  }
`;

const GroupName = styled.h3`
  font-size: 0.875rem;
  margin-bottom: 10px;
  text-transform: uppercase;
  padding: 0 16px;
`;

const FlexGrid = styled.div`
  display: flex;
  ${({ align }) => align && `align-items: ${align}`};
  ${({ column }) => column && "flex-direction: column"};
  ${({ justify }) => justify && `justify-content: ${justify}`};
`;

const StyledLink = styled.a`
  ${linkStyle()}
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SiteTitle = styled.h3`
  font-size: 1.25rem;
  text-align: center;
  color: black;
  font-weight: bold;

  @media (min-width: 620px) {
    font-size: 1.5rem;
  }
`;

const SiteLink = styled.a`
  margin: 20px 0;
  text-align: center;
  color: ${(props) => props.theme.siteTheme.color};
`;

const PublitLink = styled.a`
  font-style: italic;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.siteTheme.color};
`;
