import ProfileImage from "@s/components/common/ProfileImage";
import s from "styled-components";

import { useSite } from "@s/lib/context";

export default function ModernProfile() {
  const site = useSite();

  return (
    <Profile>
      <Content>
        <ImageContainer>
          <ProfileImage />
        </ImageContainer>
        <Title>{site.about.name}</Title>
        <Subtitle>{site.about.institution}</Subtitle>
        <Description>{site.about.degree}</Description>
      </Content>
    </Profile>
  );
}

const Profile = s.div`
margin-right: 60px;
margin-top: 100px;
padding: 0 10px;

@media (max-width: 940px) {
  margin: 0;
}
`;

const Content = s.div`
display: flex;
flex-direction: column;
align-items: center;
max-width: 300px;
margin: 0 auto;
text-align: center;

@media (max-width: 375px) {
  max-width: 100%;
  width: auto;
}
`;

const ImageContainer = s.div`
width: 120px;
height: 120px;
margin-bottom: 15px;

background: ${(props) => props.theme.siteTheme.color};
border-radius: 100%;
overflow: hidden;

& img {
  border-radius: 100%;
}
`;

const Title = s.h1`
font-size: 20px;
color: ${(props) => props.theme.siteTheme.color};
`;

const Subtitle = s.h2`
margin: 5px 0;
font-size: 18px;
`;

const Description = s.p``;
