import Link from "next/link";
import React from "react";
import styled from "styled-components";

const Navigation = () => {
  return (
    <Nav>
      <Link passHref href="/" legacyBehavior>
        <NavItem>Home</NavItem>
      </Link>
      <Link passHref href="/about" legacyBehavior>
        <NavItem>About</NavItem>
      </Link>
      <Link passHref href="/work" legacyBehavior>
        <NavItem>Work</NavItem>
      </Link>
      <Link passHref href="/contact" legacyBehavior>
        <NavItem>Contact</NavItem>
      </Link>
      {/* <Link passHref href="/blog">
        <NavItem>Blog</NavItem>
      </Link> */}
    </Nav>
  );
};

export default Navigation;

const Nav = styled.nav`
  align-items: center;
  background-color: ${(props) => props.theme.siteTheme.color};
  display: flex;
  height: 40px;
  justify-content: center;

  @media (min-width: 620px) {
    height: 60px;
    font-size: 1.125rem;
  }
`;

const NavItem = styled.a`
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 300;
  height: 100%;
  line-height: 1;
  margin: 0 16px;
  position: relative;

  &:after {
    background-color: #d8d8d8;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: all 200ms ease;
    width: 100%;
  }

  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
    }
  }
`;
