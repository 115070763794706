import Head from "next/head";
import s from "styled-components";

import Content from "./content";
import Header from "./header";
import System from "./system";

export default function ComingSoonPage() {
  return (
    <System>
      <Head>
        <title>Publit</title>
        <meta
          name="description"
          content="Publit is a premium website builder for professionals, phd candidates, writers, academics, journalists, and ambitious students."
        />
        <meta name="theme-color" content="#007FF5" />
        <link
          rel="icon"
          type="image/png"
          href="/static/coming-soon/favicon.png"
        />
        <link
          rel="icon"
          type="image/svg+xml"
          href="/static/coming-soon/favicon.svg"
        />
        <link
          rel="apple-touch-icon"
          href="/static/coming-soon/apple-touch-icon.png"
        />
      </Head>

      <LandingPage>
        <Stripes>
          <Stripe />
          <Stripe />
          <Stripe />
        </Stripes>
        <ContentWrapper>
          <Header />
          <Content />
        </ContentWrapper>
      </LandingPage>
    </System>
  );
}

const LandingPage = s.div`
position: relative;
display: flex;
align-items: stretch;
height: 100vh;
background: linear-gradient(180deg, #EAFAFF 0%, #FBFEFF 100%);
`;

const Stripes = s.div`
position: absolute;
top: 50%;
width: 100%;
height: 120px;
display: flex;
flex-direction: column;
align-items: center;
transform: skewY(-20deg) translateY(-50px);
`;

const Stripe = s.span`
display: block;
width: 100%;
height: 33%;

&:nth-child(1) {
  background: #133364;
}

&:nth-child(2) {
  background: #007FF5;
}

&:nth-child(3) {
  background: #1FACFF;
}
`;

const ContentWrapper = s.div`
position: relative;
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
`;
