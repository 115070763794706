import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Facebook from "./Facebook";
import Instagram from "./Instagram";
import LinkedIn from "./LinkedIn";
import Twitter from "./Twitter";
import Youtube from "./YouTube";

function SocialLinks({ links, isFooter }) {
  return (
    <Container>
      {links.linkedIn ? (
        <SocialLink
          href={links.linkedIn}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="LinkedIn"
        >
          <LinkedIn isFooter={isFooter} />
        </SocialLink>
      ) : null}

      {links.twitter ? (
        <SocialLink
          href={links.twitter}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Twitter"
        >
          <Twitter isFooter={isFooter} />
        </SocialLink>
      ) : null}

      {links.facebook ? (
        <SocialLink
          href={links.facebook}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Facebook"
        >
          <Facebook isFooter={isFooter} />
        </SocialLink>
      ) : null}

      {links.instagram ? (
        <SocialLink
          href={links.instagram}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Instagram"
        >
          <Instagram isFooter={isFooter} />
        </SocialLink>
      ) : null}

      {links.youtube ? (
        <SocialLink
          href={links.youtube}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="YouTube"
        >
          <Youtube isFooter={isFooter} />
        </SocialLink>
      ) : null}
    </Container>
  );
}

SocialLinks.propTypes = {
  links: PropTypes.shape({
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    twitter: PropTypes.string,
    linkedIn: PropTypes.string,
    youtube: PropTypes.string,
  }).isRequired,
};

export default React.memo(SocialLinks);

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const SocialLink = styled.a`
  padding: 10px;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;
