import { darken } from "polished";
import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import s from "styled-components";

import { useSite, useSiteInfo } from "@s/lib/context";

import RetinaPicture from "@s/components/common/Retina";
import { Section, Title } from "../shared";

interface FormData {
  name: string;
  email: string;
  company: string;
  body: string;
}

export default function MinimalContact() {
  const site = useSite();
  const siteInfo = useSiteInfo();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [buttonText, setButtonText] = useState("Send Message");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    setLoading(true);
    setError(undefined);

    if (siteInfo.isPreview) {
      setError("Disabled during live preview.");
      setLoading(false);
      return;
    }

    const res = await fetch("/api/ms/contact-me", {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const data = await res.json();

    setLoading(false);
    if (data.success) {
      reset();
      setButtonText("Thank You!");
    } else {
      setError(data.error?.message ?? "Failed to send your message.");
    }
  };

  return (
    <>
      <ImageSection>
        <RetinaPicture
          alt="Contact Image"
          imagePlace="contact"
          fallbackCropName="wide"
          sources={[
            {
              cropName: "wide",
              breakdowns: ["1024w", "2048w", "3072w"],
              sizes: "(min-width: 940px) 500px, 100vw",
            },
          ]}
        />
      </ImageSection>
      <Section smallBottomPadding>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Contact Me</Title>
          <Input
            {...register("name", { required: true, maxLength: 100 })}
            placeholder="Name"
            error={!!errors.name}
          />
          <Input
            {...register("email", { required: true, maxLength: 100 })}
            type="email"
            placeholder="Email"
            error={!!errors.email}
          />
          <Input
            {...register("company", { maxLength: 100 })}
            placeholder="Company, Institution, or Organization"
            error={!!errors.company}
          />
          <Textarea
            {...register("body", { required: true, maxLength: 500 })}
            placeholder="Message or Questions"
            error={!!errors.body}
          />
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Sending..." : buttonText}
          </Button>
          <Alternative>
            {!site.about.hideEmail && (
              <>
                {" or email me at "}
                <Link href={`mailto:${site.about.email}`}>
                  {site.about.email}
                </Link>
              </>
            )}
          </Alternative>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
      </Section>
    </>
  );
}

const ImageSection = s(Section)`
padding: 0 !important;
overflow: hidden;
border: none !important;
`;

const Form = s.form``;

const Input = s.input<{ error?: boolean }>`
display: block;
width: 100%;
padding: 10px;
margin-bottom: 10px;

outline: none;
border: 1px solid ${(props) => (props.error ? "red" : "#d7d7d7")};
border-radius: 4px;
font: inherit;

&:focus {
  border-color: ${(props) => props.theme.siteTheme.color};
}

&:first-of-type {
  margin-top: 20px;
}
`;

const Textarea = s.textarea<{ error?: boolean }>`
display: block;
width: 100%;
padding: 10px;
margin-bottom: 10px;

outline: none;
border: 1px solid ${(props) => (props.error ? "red" : "#d7d7d7")};
border-radius: 4px;
font: inherit;

resize: vertical;

&:focus {
  border-color: ${(props) => props.theme.siteTheme.color};
}
`;

const Button = s.button`
position: relative;
top: 0;

display: block;
width: 100%;
border: none;
border-radius: 4px;
padding: 10px;
background: ${(props) => props.theme.siteTheme.color};
color: white;
font: inherit;
text-transform: uppercase;
cursor: pointer;
transition: top 100ms ease, box-shadow 100ms ease;

&:disabled {
  cursor: wait;
  pointer-events: none;
}

&:hover {
  top: -2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  background: ${(props) => darken(0.05, props.theme.siteTheme.color)};
}

&:active {
  top: 0;
  box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.2);
  background: ${(props) => darken(0.05, props.theme.siteTheme.color)};
}
`;

const Alternative = s.p`
margin: 1rem 0;

text-align: center;
`;

const Link = s.a`
color: ${(props) => props.theme.siteTheme.color};
`;

const ErrorMessage = s.p`
margin: 1rem 0;

color: red;
text-align: center;
`;
